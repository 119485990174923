import React, { Suspense } from 'react'
import { Outlet } from 'react-router-dom'
import LoaderComponent from '../components/Loader'

export default function Layout() {

  return (
    <div className="grid grid-cols-1 place-items-center items-center h-full w-full bg-gray-400">
      <div className="max-w-[370px] min-w-[320px]  mx-auto ">
        <div className="w-full flex flex-col bg-white relative rounded-2xl">
          <Suspense fallback={<LoaderComponent />}>
            <Outlet />
          </Suspense>
          <div className="flex flex-col justify-center items-center text-xs text-gray-200">
            <a href="//beian.miit.gov.cn/" target="_blank" rel="noopener noreferrer">京ICP备14042301号-9</a>
          </div>
        </div>
      </div>
    </div>
  )
}
