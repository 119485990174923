import React from 'react'

export default function Loader() {
    return (
        <div className="grid place-items-center items-center h-full w-full min-h-32 mx-auto ">
            <div
                // style="background-image: conic-gradient(from 0deg, violet, indigo 30%, blue 50%, green 60%, yellow 70%, orange 80%, red 100%);"
                style={{
                    backgroundImage: 'conic-gradient(from 0deg, red 0%, pureple 12.5%, blue 25%, cyan 37.5%, green 50%, yellow 62.5%, orange 75%, red 100%)',
                }}
                className="w-20 h-20 border-2 border-gray-400 rounded-full bg-radial bg-gradient-to-tr animate-spin [animation-delay:.1s] grid place-items-center"
            >
                <div className="w-5 h-5 border-2 border-gray-400 bg-white rounded-full"></div>
            </div>
        </div>
    )
}
