
// import { Skeleton } from './Settings';
// import { formatTimestampToDate } from './Utils';
import Api from '../utils/api';
import { formatTimestampToDate } from '../utils/utility';
export const dataEbadgeFormatter = (ebadge) => {
    return {
        ...ebadge,
        key: ebadge.id,
        startymd: ebadge.startymd ? ebadge.startymd : formatTimestampToDate(ebadge.starttime, 'Y年M月D日'),
        starthms: ebadge.starthms ? ebadge.starthms : formatTimestampToDate(ebadge.starttime, 'h:m'),
        closeymd: ebadge.closeymd ? ebadge.closeymd : formatTimestampToDate(ebadge.closetime, 'Y年M月D日'),
        closehms: ebadge.closehms ? ebadge.closehms : formatTimestampToDate(ebadge.closetime, 'h:m'),
    }
}


export const getEbadge = async (ebadgeid) => {
    return await Api.get(`/ebadges/${ebadgeid}`,{ebadgeid:ebadgeid, t:new Date().getTime()}).then((res) => {
        return dataEbadgeFormatter(res);
    })
}
