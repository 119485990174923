import React from 'react';
import { RouterProvider } from 'react-router-dom';
import router from "./routes/app.js";
import { validLicenseSettings } from './utils/api.js';
import './App.css';

validLicenseSettings();

function App() {

  return (
    <React.Suspense fallback={<div>Loading...</div>}>
      <RouterProvider router={router} />
    </React.Suspense>
  );
}

export default App;
